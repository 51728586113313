<template>
    <div class="max-w-5xl px-10 pt-6 mx-auto xl:px-0">
      <div class="pb-1 pt-6">
        <h2 class="ml-2 text-5xl font-extrabold leading-none text-gray-800 xl:text-4xl sm:text-center lg:text-left">
            Liste des Produits
        </h2>
      </div>

      <div class="flex flex-wrap mt-10 overflow-hidden">
        <!-- Begining of Sidebar -->
        <div class="w-full mt-12 overflow-hidden md:w-2/6 lg:w-2/6 xl:w-2/6 md:mt-0">
            <div class="ml-2 mt-4 mr-2 md:ml-4">
                <div class="rounded-lg bg-white p-7">
                    <div class="pb-6 text-left">
                      <h2 class="mb-1 text-lg font-extrabold text-gray-800 uppercase">FILTER</h2>
                          
                      <div v-if="loadingCategList" class="mt-8">
                        <div v-for="i in 3" :key="i" class="p-1 mb-2 w-full mx-auto">
                          <div class="animate-pulse flex space-x-4">
                            <div class="h-2 bg-gray-400 rounded-sm w-5/12"></div>

                            <div class="w-6/12"></div>

                            <div class="items-end h-2 bg-gray-400 rounded-sm w-1/12"></div>
                          </div>
                        </div>
                      </div>

                      <div v-else class="mt-8">

                        <div class="flex">
                          <span class="flex-1 cursor-pointer block text-md text-primary font-medium text-gray-700">
                            <h3 class="mb-1 text-sm font-semibold text-gray-800 uppercase">{{ $t('Category') }}</h3>
                          </span>
                          <span class="text-xs font-thin text-gray-700">
                            <span v-show="filterByCategId!=0" @click="cancelFilterByCateg()" class="cursor-pointer text-primary">Afficher tout</span>
                          </span>
                        </div>

                        <ul class="mt-2">
                          <span v-for="(item, index) in categList" :key="index" >
                            <li class="flex" v-if="item.produits_count>0">
                              <span @click="filterByCateg(item.id)" :class="{'font-bold': item.id == filterByCategId}" class="flex-1 cursor-pointer uppercase block py-2 text-md text-primary hover:opacity-75 font-medium text-gray-700">{{ item.nom }}</span>
                              <span class="p-2 text-lg font-thin text-gray-700" :class="{'font-medium': item.id == filterByCategId}">{{ item.produits_count }}</span>
                            </li>
                          </span>
                        </ul>
                      </div>
                    </div>
                </div>              
            </div>
        </div>

        <div class="w-full overflow-hidden md:w-4/6 lg:w-4/6 xl:w-4/6">
            <div class="mt-4 ml-2 mr-2 md:mr-4">

              <div v-if="selectedStartEndDateTime" class="mb-6 bg-secondary rounded-md text-white p-3">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>

                <div class="inline text-lg font-medium pl-2">
                  {{ $moment(selectedStartEndDateTime.startDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY') }} {{ $moment(selectedStartEndDateTime.startTime, 'DD-MM-YYYY HH:mm:ss').format('HH:mm') }} / {{ $moment(selectedStartEndDateTime.endDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY') }} {{ $moment(selectedStartEndDateTime.endTime, 'DD-MM-YYYY HH:mm:ss').format('HH:mm') }}
                </div>

                <div class="inline text-lg font-medium pl-2 float-right" v-if="dateDifference">
                  <span v-if="dateDifference.d == 1">{{ dateDifference.d }} jour</span>
                  <span v-else-if="dateDifference.d >= 2">{{ dateDifference.d }} jours</span>
                  
                  <span v-if="dateDifference.h == 1">
                    <span v-if="dateDifference.d > 0">,</span> {{ dateDifference.h }} heure
                  </span>
                  <span v-else-if="dateDifference.h >= 2">
                    <span v-if="dateDifference.d > 0">,</span> {{ dateDifference.h }} heures
                  </span>
                </div>

              </div>
              
                
                <PopupShowProduct
                    :isPopupActive="showProductCartPopupActive"
                    @closeSidebar="toggleDataIsPoupupAddAndEditActive"
                />

                <div class="col-span-12 md:col-span-8" v-if="avProductsList.length>0">

                  <div v-if="loadingAvProductsList">
                    <div v-for="i in 3" :key="i" class="border border-gray-50 shadow rounded-md p-4 mb-2 w-full mx-auto">
                      <div class="animate-pulse flex space-x-4">
                        <div class="bg-gray-400 h-40 w-40 rounded-sm"></div>
                        <div class="flex-1 space-y-4 pt-4 py-1">
                          <div class="h-4 bg-gray-400 rounded w-3/4"></div>
                          <div class="space-y-2">
                            <div class="h-4 bg-gray-400 rounded"></div>
                            <div class="h-4 bg-gray-400 rounded w-5/6"></div>
                          </div>
                        </div>

                        <div class="bg-gray-400 h-8 w-20 rounded-sm"></div>
                      </div>
                    </div>
                  </div>

                  <div v-for="(product, index) in avProductsList" :key="index">

                    <div v-show="filterByCategId==0 || product.categ_id === filterByCategId" class="flex flex-wrap content-center items-center bg-white mt-2">
                        <a  class="w-full md:w-2/5 md:h-40 overflow-hidden">
                            <img v-if="product.image && product.image.small" class="mx-auto max-h-full p-2 transition duration-300 ease-out transform hover:scale-105" :src="product.image.small" :alt="'Problème chargement d\'image'">
                            <img v-else class="max-h-full p-2 transition duration-300 ease-out transform hover:scale-105" src="@/assets/images/no-image-400x400.png" :alt="'Problème chargement d\'image'">
                            <!-- <img class="w-full h-auto transition duration-300 ease-out transform scale-100 bg-cover hover:scale-105" :src="product.img" :alt="'Problème chargement d\'image'"> -->
                        </a>
                        <div class="w-full md:w-2/5 p-2 border-dashed md:border-r border-gray-200 text-center">
                          <h2 class="mb-2 text-lg text-gray-700">{{ product.title }}</h2>
                          <p class="mb-3 text-sm text-gray-800" v-if="product.short_description">{{ product.short_description.length >= 80 ?  product.short_description.substring(0, 80) + '...' : product.short_description }}</p>
                          <p class="mb-3 text-sm text-gray-800" v-else-if="product.description">{{ product.description.length >= 80 ?  product.description.substring(0, 80) + '...' : product.description }}</p>
                        </div>
                        <div class="w-full md:w-1/5 text-center">
                          <div class="mb-1 text-sm text-gray-400" v-if="product.price">
                            <div class="date-difference" v-if="dateDifference"> 
                              <span v-if="dateDifference.d == 1">{{ dateDifference.d }} jour</span>
                              <span v-else-if="dateDifference.d >= 2">{{ dateDifference.d }} jours</span>
                              
                              <span v-if="dateDifference.h == 1">
                                <span v-if="dateDifference.d > 0">,</span> {{ dateDifference.h }} heure
                              </span>
                              <span v-else-if="dateDifference.h >= 2">
                                <span v-if="dateDifference.d > 0">,</span> {{ dateDifference.h }} heures
                              </span>
                            </div>
                          </div>
                          <del v-if="product.old_price">
                            <span class="product-old-price">{{ parseFloat(product.old_price).toFixed(2) }} €</span>
                          </del>
                          <p class="mb-3 text-sm text-gray-800" v-if="product.price"> {{ parseFloat(product.price).toFixed(2) }} €</p>
                          <button @click="reserve(product.id, product)" class="product-list-btn-reserve bg-primary rounded-lg hover:opacity-75 sm:mb-6 xs:w-1/2 font-medium text-white py-2 px-3 rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                            Réserver
                            <!-- <div class="animate-spin rounded-full h-4 w-4 border-b-2 white" style="display: inline-block;"></div> -->
                          </button>
                        </div>
                    </div>

                  </div>
                </div>

            </div>

        </div>
      </div>
    </div>
</template>

<script>

import axios from '@/axios.js'
const PopupShowProduct = () => import("@/views/welcome-page/PopupShowProduct.vue");
import moment from 'moment';

export default {
  name: "ProductList",

  props: {
    isValidatedStep1: {
      type: Boolean,
      required: true
    },
    oldReservationProducts: {
      // type: Boolean,
      // required: true
    }
  },

  data: () => ({
    title: "ProductList",
    activePrompt: false,
    search: "",
    
    isRangeOfDatetimeClicked: false,
    showProductCartPopupActive: false,
    showProductCartPopupFromCart: false,
    productCartIndex: null,
    showProductCartPopup: {},
    showProductOptionPopup: null,
    filterByCategId: 0,
  }),
  methods: {
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    showPopup(data = null, isUpdate = false) {

      console.clear()
      console.log('showPopup')
      console.log('isUpdate', isUpdate)

      if(data != null) {
        var selectedStartEndDateTime = {}
        var quantityRequested = 1

        if(isUpdate) {
          selectedStartEndDateTime = {
            startDate : data.selectedStartEndDateTime.startDate,
            endDate : data.selectedStartEndDateTime.endDate,
            startTime : data.selectedStartEndDateTime.startTime,
            endTime : data.selectedStartEndDateTime.endTime,
          }

          quantityRequested = data.quantityRequested
        }
        else {
          const startDate = moment(this.selectedStartEndDateTime.startDate, 'YYYY-MM-DD')
          const endDate = moment(this.selectedStartEndDateTime.endDate, 'YYYY-MM-DD')
          const startTime = moment(this.selectedStartEndDateTime.startTime, 'hh:mm')
          const endTime = moment(this.selectedStartEndDateTime.endTime, 'hh:mm')
  
          selectedStartEndDateTime = {
            startDate : new Date(moment(startDate, 'DD-MM-YYYY hh:mm:ss')),
            endDate : new Date(moment(endDate, 'DD-MM-YYYY hh:mm:ss')),
            startTime : new Date(moment(startTime, 'DD-MM-YYYY hh:mm:ss')),
            endTime : new Date(moment(endTime, 'DD-MM-YYYY hh:mm:ss')),
          }
        }


        console.log('data selectedStartEndDateTime', data.selectedStartEndDateTime)
        console.log('selectedStartEndDateTime', selectedStartEndDateTime)

        var product_price = data.price ? data.price : data.product_price
        data.quantityRequested = quantityRequested
        data.product_price = parseFloat(Number(product_price)).toFixed(2)
        data.total = parseFloat(Number(product_price) * Number(data.quantityRequested)).toFixed(2)
        data.selectedStartEndDateTime = selectedStartEndDateTime
      }

      console.log('data FROM showPopup default', data)
      
      this.$store.dispatch("eCommerce/createEmptyPopup", data);
      this.$store.dispatch('eCommerce/changeTheValueOfUpdateButton', isUpdate)
      this.showProductCartPopupActive = true
    },
    reserve(productId, product) {

      console.log('product', product)

      if(!product) return

      if(product.price && product.price>0) {

        product.total = product.price
        product.quantityRequested = 1
        product.selectedStartEndDateTime = this.selectedStartEndDateTime

        console.log('selectedStartEndDateTime', this.selectedStartEndDateTime)

        this.$store.dispatch("eCommerce/createEmptyPopup", product);
        this.toggleDataIsPoupupAddAndEditActive(true)
      }

      else {
        const selectedStartEndDateTime = {
          startDate: this.popupSelectedStartDate,
          startTime: this.popupSelectedStartTime,
          endDate: this.popupSelectedEndDate,
          endTime: this.popupSelectedEndTime,
        }
  
        console.log('selectedStartEndDateTime', selectedStartEndDateTime)
  
        this.addSelectedStartEndDateTimeInCart(selectedStartEndDateTime)
      
        this.getAvProduct(productId, 1)
      }
    },
    addSelectedStartEndDateTimeInCart (item) {
      this.$store.dispatch('eCommerce/addSelectedStartEndDateTimeInCart', item)
    },
    async getAvProduct (productId = 0, quantityRequested = 1, excludeReservationProductUuid = null) {
      console.log('getAvProduct')
      this.$vs.loading()

      const payload = {
        startDate: moment(this.selectedStartEndDateTime.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.selectedStartEndDateTime.endDate).format('YYYY-MM-DD'),
        startTime: moment(this.selectedStartEndDateTime.startTime).format('HH:mm'),
        endTime: moment(this.selectedStartEndDateTime.endTime).format('HH:mm'),
        productId: productId ? productId : this.showProductCartPopup.id,
        excludeReservationProductUuid: excludeReservationProductUuid ? excludeReservationProductUuid : this.showProductCartPopup.reservationProductUuid,
        quantity: quantityRequested ? quantityRequested : this.quantityRequested,

        notify: this.$vs.notify,
        loading: this.$vs.loading
      }
      await this.$store.dispatch('eCommerce/getAvProduct', payload)
      .then(res => {
        if (res.status == 200 || res.status == 201) {
          this.$vs.loading.close()
        }

        const product = res.data.data[0]

        product.total = product.price
        product.quantityRequested = quantityRequested ? quantityRequested : this.quantityRequested
        product.selectedStartEndDateTime = this.selectedStartEndDateTime

        this.$store.dispatch("eCommerce/createEmptyPopup", product);

        this.toggleDataIsPoupupAddAndEditActive(true)
      })
      .catch(err => {
        console.error(err)
        this.selectedStartEndDateTime = this.showProductCartPopup.selectedStartEndDateTime
        this.$vs.loading.close() 
      })
      
    },
    async getCategList () {
      console.log('getCategList')
      const payload = {
        notify: this.$vs.notify,
        loading: this.$vs.loading
      }
      
      await this.$store.dispatch('eCommerce/getCategList', payload)
      .then(res => {
      })
      .catch(err => {
        console.error(err)
      })
      
    },
    toggleDataIsPoupupAddAndEditActive (val = false) {
      this.showProductCartPopupActive = val
    },
    openLoading (){
      this.$vs.loading()
      this.$store.commit('eCommerce/TOGGLE_LOADING_AV_PRODUCT_LIST', true)
    },
    closeLoading (){
      this.$vs.loading.close()
      this.$store.commit('eCommerce/TOGGLE_LOADING_AV_PRODUCT_LIST', false)
    },
    acceptAlert() {
      this.$vs.notify({
        color: "primary",
        title: "Contact Added",
        text: "Contact Added Successfully"
      });
    },
    close() {
      this.$vs.notify({
        color: "danger",
        title: "Closed",
        text: "You close a dialog!"
      });
    },
    getProductList() {
      console.log('getProductList')
      this.$store.commit('eCommerce/TOGGLE_LOADING_AV_PRODUCT_LIST', true)
      axios.get(`/api/v2/liste_products`)
        .then((response) => {
          console.log('response', response)
          console.log('response.data.data', response.data.data)
            this.$store.commit('eCommerce/ADD_AV_PRODUCT_LIST', response.data.data)
            this.closeLoading()
        })
        .catch((error) => {
          this.closeLoading()
      })
    },
    resetPopupShowProduct(item = null, defaultQty = 1, fromCart = false, selectedProductOptions = null) {
      console.log('selectedProductOptions FROM resetPopupShowProduct', selectedProductOptions)
      console.log('item FROM resetPopupShowProduct', item)
      this.isRangeOfDatetimeClicked = false
      this.showProductCartPopupFromCart = fromCart
      this.popupSelectedProductOption = selectedProductOptions
      
      this.showProductOptionPopup = selectedProductOptions

      console.log('showProductCartPopupFromCart', this.showProductCartPopupFromCart)

      // this.$refs.optionSelected

      if(this.showProductOptionPopup != null) {
        this.showProductOptionPopup.forEach((showProductOptionPopupValue) => {
          if (showProductOptionPopupValue.active) {
            this.$refs.optionSelected = true
          }
        })
      }


      
      // RESET PRODUCT DATETIME IN POPUP (Show Product)
      // this.setDateTimePopup(item ? item.requested_dates: null)

      // RESET PRODUCT QTY IN POPUP (Show Product)
      this.popupSelectedQty = item ? item.quantityRequested : defaultQty

      this.calculTotalPrice()
      
      this.isPopupSelectedStartDateChanged = false
      this.isPopupSelectedEndDateChanged = false
      this.isPopupSelectedStartTimeChanged = false
      this.isPopupSelectedEndTimeChanged = false

      console.log('FROM resetPopupShowProduct : item => ', item)

      // this.isSelectedDateTimeChanged(false);
    },
    calculTotalPrice() {
      this.showProductCartPopup.options_price = 0

      console.log('showProductOptionPopup', this.showProductOptionPopup)

      if(this.showProductOptionPopup != null) {
        this.showProductOptionPopup.forEach((showProductOptionPopupValue) => {
          if (showProductOptionPopupValue.active) {
            this.showProductCartPopup.options_price = this.showProductCartPopup.options_price + showProductOptionPopupValue.total
          }
        })
      }

      console.log('this.showProductOptionPopup', this.showProductOptionPopup)
      console.log('this.showProductCartPopup.price', this.showProductCartPopup.price)
      console.log('this.showProductCartPopup.options_price', this.showProductCartPopup.options_price)
      console.log('this.showProductCartPopup.total', this.showProductCartPopup.total)
      console.log('this.popupSelectedQty', this.popupSelectedQty)
    
      this.showProductCartPopup.total = this.showProductCartPopup.price * this.showProductCartPopup.quantityRequested + this.showProductCartPopup.options_price
    },
    getNewProductPrice() {
      this.calculTotalPrice()
    },
    getNewProductOptionPrice(item) {
      item.active = true
      item.total = item.price * item.quantityRequested

      this.calculTotalPrice()
    },
    getNewProductOptionPriceFromCheckbox(item) {
      console.clear()
      console.log('FROM getNewProductOptionPrice item', item)
      console.log('this.$refs.optionSelected', this.$refs.optionSelected)
      item.total = item.price * item.quantityRequested
      this.calculTotalPrice()
    },
    checkAvProductById(productId) {
      this.getAvProduct(productId)
      this.getAvProductOption(productId)
    },
    reserveButtonClicked (product) {
      console.log('FROM reserveButtonClicked : product', product)

      product.quantityRequested = 1
      this.showProductCartPopup = product

      const optionList = []

      product.product_options.forEach(element => {
        optionList.push({
          title: element.title,
          quantityRequested: 1,
          price: element.price,
          total: element.price,
          qty: element.qty,
          img: element.img,
          active: false
        })
      });

      this.showProductOptionPopup = optionList

      this.showProductCartPopup.productOptionsList = optionList

      console.log('showProductCartPopup', this.showProductCartPopup)

      // RESET POPUP
      this.resetPopupShowProduct(null,1,false,this.showProductOptionPopup)
      this.showProductCartPopupActive = true
    },
    accept() {
      console.log('accept')
      if(this.showProductCartPopupFromCart) {
        this.updateButtonPopupClicked()
      }
      this.confirmButtonPopupClicked()
    },
    confirmButtonPopupClicked (data = null) { // BTN CONFIRMER

      const item = data ? data : this.showProductCartPopup
      
      const selectedProductOption = []
      if(this.showProductOptionPopup != null) {
        this.showProductOptionPopup.forEach((element) => {
          if (element.active) {
            selectedProductOption.push({
              title: element.title,
              quantityRequested: element.quantityRequested,
              price: element.price,
              total: element.total,
              qty: element.qty,
              img: element.img
            })
          }
        })
      }

      const storeProductItemInCart = {
        id: item.id,
        title: item.title,
        short_description: item.short_description,
        description: item.description,
        img: item.img,
        large_image: item.large_image,
        meduim_image: item.meduim_image,
        small_image: item.small_image,
        x_small_image: item.x_small_image,
        pourcentage: item.pourcentage,
        calculated: item.calculated, // quantite disponible
        old_price: item.old_price,
        price: item.price,
        total: item.price * this.popupSelectedQty,
        quantityRequested: this.popupSelectedQty,
        allOptions: selectedProductOption
      }

      console.log('item FROM confirmButtonPopupClicked', item)
      console.log('storeProductItemInCart FROM confirmButtonPopupClicked', storeProductItemInCart)
        // if(this.popupSelectedProductOption.length>0) {
        //   console.log('popupSelectedProductOption', this.popupSelectedProductOption)
        //   item.requested_product_options = this.popupSelectedProductOption
        // }

      this.additemInCart(storeProductItemInCart)
      this.showProductCartPopupActive = false
      this.popupSelectedQty = 1 // Reset selected Qty in poupup (show product)
    },
    additemInCart (item) {
      this.$store.dispatch('eCommerce/additemInCart', item)
    },
    confirmButtonIsDisabled(availability) { 
      if(availability == false) return  true
      else if(this.isSelectedDateTimeChanged() == true) return  true
      else { return false }
    },
    updateCartButtonClicked (data) {
      console.log('FROM updateCartButtonClicked : data', data)
      // console.log('item.requested_product_options', data.item.requested_product_options)

      // if(data.item.requested_product_options) {
      //   this.resetPopupSelectedProductOptionQty(data.item.requested_product_options)
      // }

      this.showProductCartPopupFromCart = true
      this.productCartIndex = data.index // index => product index in cart
      this.showProductCartPopup = data.item // item => product in cart
      
      // this.getAvProductOption(data.item.id) // get available product option(s) from backend for this product by ID

      // RESET POPUP
      this.resetPopupShowProduct(
        data.item,
        data.item.quantityRequested, 
        this.showProductCartPopupFromCart,
        data.item.allOptions
      )

      this.showProductCartPopupActive = true
    },
    updateItemQuantity (event, index) {
      const itemIndex = Math.abs(index + 1 - this.cartItems.length)
      this.$store.dispatch('eCommerce/updateItemQuantity', { quantity: event, index: itemIndex })
    },
    setProductOptions(allProductoptions, reservationProductOptions) {
      const productOptions = []
      let totalOptionsPrice = 0

      if(allProductoptions != null) {
        
        console.log('allProductoptions', allProductoptions)
        
        allProductoptions.forEach((element) => {

          console.log('element', element)

          var selectedReservationProductoption = reservationProductOptions.find(i => i.product_options.id === element.id)

          console.log('selectedReservationProductoption', selectedReservationProductoption)

          productOptions.push({
            reservationProductOptionUuid: (typeof(selectedReservationProductoption) !== 'undefined') ? selectedReservationProductoption.uid : null,
            option_id: element.id,
            title: element.title,
            quantityRequested: (typeof(selectedReservationProductoption) !== 'undefined') ? selectedReservationProductoption.quantity : 0,
            price: (typeof(selectedReservationProductoption) !== 'undefined') ? parseFloat(Number(selectedReservationProductoption.price)).toFixed(2) : Number(element.price),
            total: (typeof(selectedReservationProductoption) !== 'undefined') ? parseFloat(Number(selectedReservationProductoption.quantity) * Number(selectedReservationProductoption.price)).toFixed(2) : 0,
            active: (typeof(selectedReservationProductoption) !== 'undefined') ? true : false,
            qty: element.qty,
            img: element.img,
          })

          if (typeof(selectedReservationProductoption) !== 'undefined') {
            totalOptionsPrice = parseFloat(Number(totalOptionsPrice) + Number(selectedReservationProductoption.quantity * selectedReservationProductoption.price)).toFixed(2)
          }
          
        })
        
        return {
          productOptions: productOptions,
          totalOptionsPrice: totalOptionsPrice,
        }

      }


    },
    setProductWithOptionsInCart (reservationProduct) { // BTN CONFIRMER
      if(!reservationProduct) return
      var findProduct = this.avProductsList.find(i => i.id === reservationProduct.products.id)
      var allProductoptions = findProduct ? findProduct.product_options : null

      // console.log('reservationProduct', reservationProduct)
      // console.log('findProduct', findProduct)
      
      const productOptionList = this.setProductOptions(allProductoptions, reservationProduct.reservation_product_options)

      // console.log('productOptionList ', productOptionList)

      console.log('reservationProduct', reservationProduct)
      console.log('findProduct', findProduct)

      var storeProductItemInCart = {}

      // selectedStartEndDateTime

      const startDate = moment(reservationProduct.start_date, 'DD/MM/YYYY hh:mm:ss')
      const endDate = moment(reservationProduct.end_date, 'DD/MM/YYYY hh:mm:ss')
      const startTime = moment(reservationProduct.start_date, 'DD/MM/YYYY hh:mm:ss')
      const endTime = moment(reservationProduct.end_date, 'DD/MM/YYYY hh:mm:ss')

      const selectedStartEndDateTime = {
        startDate : new Date(moment(startDate, 'DD-MM-YYYY hh:mm:ss')),
        endDate : new Date(moment(endDate, 'DD-MM-YYYY hh:mm:ss')),
        startTime : new Date(moment(startTime, 'DD-MM-YYYY hh:mm:ss')),
        endTime : new Date(moment(endTime, 'DD-MM-YYYY hh:mm:ss')),
      }

      storeProductItemInCart = {
        productsheetIsExist: reservationProduct.productsheet ? true : false,
        reservationProductUuid: reservationProduct.uid,
        id: reservationProduct.products.id,
        product_id: reservationProduct.products.id,
        title: reservationProduct.products.title,
        short_description: reservationProduct.products.short_description,
        description: reservationProduct.products.description,
        img: reservationProduct.products.img,
        x_small_image: reservationProduct.products.x_small_image,
        pourcentage: findProduct ? findProduct.pourcentage : false,
        calculated: findProduct ? findProduct.calculated : 0, // quantite disponible
        qty: findProduct ? findProduct.qty : 0,
        old_price: findProduct ? parseFloat(Number(findProduct.old_price)).toFixed(2) : 0,
        product_price: parseFloat(Number(reservationProduct.price)).toFixed(2),
        total_options_price: parseFloat(productOptionList.totalOptionsPrice).toFixed(2),
        total_product: parseFloat(Number(reservationProduct.price) * Number(reservationProduct.quantity)).toFixed(2),
        total: parseFloat(Number(productOptionList.totalOptionsPrice) + Number(reservationProduct.price * reservationProduct.quantity)).toFixed(2),
        quantityRequested: reservationProduct.quantity,
        allOptions: productOptionList.productOptions,
        product_options: productOptionList.productOptions,
        productOptionsList: productOptionList.productOptions,
        selectedStartEndDateTime: selectedStartEndDateTime
      }

      console.log('storeProductItemInCart', storeProductItemInCart)

      this.additemInCart(storeProductItemInCart)
    },
    clearCart () {
      this.$store.dispatch('eCommerce/clearCart')
    },
    filterByCateg(itemId) {
      console.log('filterByCateg', itemId)
      this.filterByCategId = itemId
    },
    cancelFilterByCateg() {
      this.filterByCategId = 0
    }
  },
  computed: {
    selectedStartEndDateTime: {
      get () {
        return this.$store.state.eCommerce.selectedStartEndDateTime.slice()[0]
      },
      set (value) {
        return value
      }
    },
    isWithoutDiscountActive () {
      return this.$store.state.eCommerce.isWithoutDiscountActive
    },
    popupSelectedStartDate () {
      return new Date()
    },
    popupSelectedEndDate () {
      return this.addDays(new Date(), 2)
    },
    popupSelectedStartTime () {
      return new Date(moment().format('YYYY-MM-DD 09:00'))
    },
    popupSelectedEndTime () {
      return new Date(moment().format('YYYY-MM-DD 09:00'))
    },
    avProductsList () {
      return this.$store.state.eCommerce.avProductsList
    },
    dateDifference () {
      return this.$store.state.eCommerce.dateDifference
    },
    loadingAvProductsList () {
      return this.$store.state.eCommerce.loadingAvProductsList
    },
    categList () {
      return this.$store.state.eCommerce.categList
    },
    loadingCategList () {
      return this.$store.state.eCommerce.loadingCategList
    }
  },
  watch: {
    isValidatedStep1(val) {
      console.log('START isValidatedStep1 val', val)
      if(val) {
        this.openLoading() // this.openLoadingInAvProductsList()
        this.resetPopupShowProduct()
        this.getProductList()
        this.getCategList()
        // this.getAvProduct()
        // this.$emit('productListToReservationIndexChangeIsValidatedStep1', false)
      }
    },
  },
  components: {
    PopupShowProduct
  },
  mounted() {
    this.$root.$on('request_for_update_item_in_cart', (data) => {
      console.log('request_for_update_item_in_cart data', data.item)
      this.showPopup(data.item, true)
      // this.updateCartButtonClicked(data, true)
    })
    this.getProductList()
    this.getCategList()
  }
};
</script>

<style lang="scss">

.grid-view-item .grid-view-img {
  -webkit-transition: .35s;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  transition: .35s;
}

.h-56 {
  height: 14rem !important;
}

.truncate {
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  white-space: nowrap!important;
}

.card-title-top {
  text-align: center;
}

.product-price {
  display: inline-block;
  text-align: end;
  float: right;
}

.date-difference {
  display: inline-block;
  text-align: start;
}

.product-old-price {
  text-decoration: line-through;
  color: #636060bf;
}

// .product-price {
//   del {
//     color: rgba(darkgray, 0.5);
//     text-decoration: none;
//     position: relative;
//     &:before {
//       content: " ";
//       display: block;
//       width: 100%;
//       border-top: 2px solid rgba(darkgray, 0.8);
//       height: 12px;
//       position: absolute;
//       bottom: 0;
//       left: 0;
//       transform: rotate(-7deg);
//     }
//   }
// }

.btn-reserver span, .btn-confirmer span{
  text-transform: uppercase;
}

.con-vs-dialog .vs-dialog{
-webkit-transition: all .2s;
    transition: all .2s;
    z-index: 100;
    width: 600px;
    margin: 10px;
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0,0,0,.1);
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.1);
    background: #fff;
    -webkit-animation: rebound .3s;
    animation: rebound .3s;
}

.btn-reserver-block {
  margin-top: 1em;
}

.btn-reserver {
  position: absolute;
  bottom: -2px;
  left: 0px;
}

.disabled-card {
  pointer-events: none!important;
  // background-color: #ccc;
  opacity: 0.5!important;
  filter: alpha(opacity=50)!important;
  zoom: 1!important;  
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";  
  -moz-opacity: 0.3!important; 
  -khtml-opacity: 0.3!important;
}

.badge-out-of-stock {
  background: #f62d51;
  border-radius: 5px 0 0 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 10px;
  position: absolute;
  right: 0px;
  top: 17%;
}

@media (max-width: 992px) { // mobile - tablette
  .product-list-btn-reserve{
    width: 90%!important;
    padding-top: .8rem !important;
    padding-bottom: .8rem !important;
  }
}

</style>
